<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ selectedItem ? "Editar" : "Agregar" }} ruleta</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          :loading="loading"
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="pl-5 pr-5">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-tabs v-model="tab">
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab key="general">General</v-tab>
                <v-tab key="style">Estilos</v-tab>
              </v-tabs>
            </v-col>

            <v-col class="ma-0 pa-0" cols="12" sm="12" md="12">
              <v-tabs-items v-model="tab">
                <v-tab-item key="general">
                  <general :loading="loading" :roulette="item" />
                </v-tab-item>

                <v-tab-item key="style">
                  <styles :loading="loading" :roulette="item" />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid class="pl-5 pr-5">
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="item.active"
                  :loading="loading"
                  label="Activo"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  @click="selectedItem ? update() : save()"
                  :loading="loading"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";
import general from "./tabs/general";
import styles from "./tabs/styles";

export default {
  name: "edit-event",
  props: ["selectedItem"],
  components: {
    general,
    styles,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: true,
      tab: null,
      item: {
        active: true,
        terms: ["Termino 1", "Termino 2"],
        backgroundStyles: [
          {
            property: "backgroundColor",
            value: "#ff5900",
            type: "default",
          },

          { property: "backgroundImage", value: "http://", type: "default" },
        ],

        titleStyles: [
          {
            property: "color",
            value: "#a8a8a8",
            type: "default",
          },
          {
            property: "fontSize",
            value: "2vw",
            type: "default",
          },

          {
            property: "fontFamily",
            value: "comic sans ms, cursive",
            type: "default",
          },
        ],

        pointerStyles: [
          {
            property: "backgroundColor",
            value: "#ff5900",
            type: "default",
          },
        ],

        buttonStyles: [
          {
            property: "fontWeight",
            value: "bold",
            type: "default",
          },
          {
            property: "fontSize",
            value: "2vw",
            type: "default",
          },
          {
            property: "backgroundColor",
            value: "#ff5900",
            type: "default",
          },
          {
            property: "color",
            value: "#a8a8a8",
            type: "default",
          },
          {
            property: "fontFamily",
            value: "comic sans ms, cursive",
            type: "default",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["user", "groupId"]),
  },
  methods: {
    async update() {
      try {
        await this.validation();

        this.loading = true;
        let data = {
          title: this.item.title,
          description: this.item.description ? this.item.description : "",
          active: this.item.active,
          terms: this.item.terms.filter((e) => e),
          modifiedAt: new Date(),
          modifiedBy: this.user.uid,
          deleted: false,
          backgroundStyles: this.item.backgroundStyles || [],
          titleStyles: this.item.titleStyles || [],
          buttonStyles: this.item.buttonStyles || [],
          pointerStyles: this.item.pointerStyles || [],
        };

        db.collection("games")
          .doc(this.selectedItem[".key"])
          .update(data)
          .then((response) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            this.loading = false;
          });
      } catch (error) {
        this.snackbarText = error;
        this.snackbar = true;
      }
    },
    async save() {
      try {
        await this.validation();

        this.loading = true;
        let data = {
          title: this.item.title,
          description: this.item.description ? this.item.description : "",
          active: this.item.active,
          terms: this.item.terms.filter((e) => e),
          createdAt: new Date(),
          createdBy: this.user.uid,
          deleted: false,
          type: "roulette",
          groupId: this.groupId,
          backgroundStyles: this.item.backgroundStyles || [],
          titleStyles: this.item.titleStyles || [],
          buttonStyles: this.item.buttonStyles || [],
        };

        db.collection("games")
          .add(data)
          .then((response) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            this.loading = false;
          });
      } catch (error) {
        this.snackbarText = error;
        this.snackbar = true;
      }
    },

    validation() {
      return new Promise(async (resolve, reject) => {
        if (!this.item.title) {
          return reject("Debe ingresar el nombre del evento.");
        }

        // if (!this.selectedItem && !this.item.code) {
        //   return reject("Debe ingresar el código del evento.");
        // }

        // let list = await db
        //   .collection("roulette")
        //   .where("code", "==", this.item.code)
        //   .get();

        // if (!this.selectedItem && list.size) {
        //   return reject("El código ingresado ya existe.");
        // }

        return resolve("success");
      });
    },
  },
  async mounted() {
    this.loading = false;

    if (this.selectedItem) {
      this.item = Object.assign({}, this.selectedItem);
    }
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  overflow-x: hidden !important;
}
</style>