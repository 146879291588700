<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">{{ $t("zones.singleFile.source") }}</span>
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                :loading="loading"
                large
                rounded
                :elevation="0"
                class="flex-grow-1 flex-shrink-0 white--text"
                :minHeight="65"
                @click="showAllFilesDialog"
                block
                color="#949494"
              >
                <v-icon left> fas fa-image </v-icon>
                {{ $t("zones.singleFile.select_existing_image") }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                :loading="loading"
                large
                rounded
                :elevation="0"
                class="flex-grow-1 flex-shrink-0 white--text"
                :minHeight="65"
                @click="addFromPc"
                block
                color="#949494"
              >
                <v-icon left> fas fa-cloud-upload-alt </v-icon>
                {{ $t("zones.singleFile.upload_image_from_this_device") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-if="allFilesDialog" max-width="90%" v-model="allFilesDialog">
      <all-files
        :fileType="fileType"
        :selectedItem="selectedItem"
        @success="handleSuccess"
        @cancel="
          allFilesDialog = false;
          $emit('cancel');
        "
      />
    </v-dialog>

    <v-dialog
      v-if="addFromPcDialog"
      persistent
      v-model="addFromPcDialog"
      :max-width="selectedImage ? '600px' : '900px'"
    >
      <add-item
        @success="fileUploaded"
        :mediaType="fileType"
        @cancel="addFromPcDialog = false"
        :itemName="fileType == 'image' ? 'imagen' : 'video'"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import allFiles from "./all-files";
import addItem from "@/components/add-item";

export default {
  name: "choose-file-source",
  props: ["fileType", "selectedItem"],
  components: {
    allFiles,
    addItem,
  },
  data() {
    return {
      allFilesDialog: false,
      addFromPcDialog: false,
    };
  },

  methods: {
    showAllFilesDialog() {
      this.allFilesDialog = true;
    },
    addFromPc() {
      this.addFromPcDialog = true;
    },
    fileUploaded(result) {
      let data = {
        fileId: result.id,
        type: "singleFile",
        adjustment: "fill",
        fileType: result.type,
        url: result.url,
        width: 500,
        height: 300,
        x: 0,
        y: 0,
      };
      this.addFromPcDialog = false;
      this.$emit("success", data);
    },
    handleSuccess(result) {
      let data = {};

      if (this.selectedItem) {
        data = JSON.parse(JSON.stringify(this.selectedItem.data));
        data.url = result.url;
        data.fileId = result[".key"];
        data.fileType = result.type;
        data.adjustment = "fill";

        this.$emit("updated", data);
      } else {
        data = {
          fileId: result[".key"],
          type: "singleFile",
          adjustment: "fill",
          fileType: result.type,
          url: result.url,
          width: 500,
          height: 300,
          x: 0,
          y: 0,
        };
        this.$emit("success", data);
      }
    },
  },
};
</script>