<template>
  <v-container>
    <v-row>
      <v-tabs color="secondary" v-model="tab">
        <v-tab key="background">Fondo</v-tab>
        <v-tab key="title">Título</v-tab>
        <v-tab key="buttons">Botones</v-tab>
        <v-tab key="pointer">Puntero</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="background" class="mt-10">
          <css :stylesArray="roulette.backgroundStyles" />
        </v-tab-item>

        <v-tab-item key="title" class="mt-10">
          <css :stylesArray="roulette.titleStyles" />
        </v-tab-item>

        <v-tab-item key="buttons" class="mt-10">
          <css :stylesArray="roulette.buttonStyles" />
        </v-tab-item>

        <v-tab-item key="pointer" class="mt-10">
          <css :stylesArray="roulette.pointerStyles" />
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </v-container>
</template>
  
  
  <script>
import css from "@/components/css";

export default {
  name: "styles-roulette",
  props: ["roulette", "loading"],

  components: {
    css,
  },

  data() {
    return {
      tab: null,
    };
  },
};
</script>
  
  <style scoped>
input[type="color"] {
  -webkit-appearance: none;
  border: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
</style>