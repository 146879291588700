<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Ganadores de {{ prize.title }}</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="search"
                clearable
                rounded
                flat
                filled
                hide-details
                :label="`Buscar ganador`"
                prepend-inner-icon="fa-search"
                class="d-none d-md-flex"
              />
            </v-col>
            <!-- <v-col cols="12" md="5" sm="5">
                <v-row justify="end">
                  <v-btn
                    color="primary"
                    class="ma-2 white--text mr-5"
                    @click="sortDialog = true"
                    :loading="loading"
                  >
                    <v-icon right dark class="mr-3">fas fa-bars</v-icon>
                    Ordenar
                  </v-btn>
  
                  <v-btn
                    color="primary"
                    class="ma-2 white--text"
                    @click="addPrize"
                    :loading="loading"
                  >
                    <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                    Agregar premio
                  </v-btn>
                </v-row>
              </v-col> -->
          </v-row>

          <v-data-table
            :headers="headers"
            :items="prize.users"
            :search="search"
            :items-per-page="5"
            fixed-header
            :loading="loading"
            :sort-by="['position']"
            class="mt-5"
            :sort-desc="[false, true]"
            item-key="id"
            :footer-props="{
              itemsPerPageOptions: [10],
            }"
          >
            <template v-slot:[`item.active`]="{ item }">
              <v-switch
                :loading="loading"
                @change="
                  switchControlChanged(
                    { active: item.active },
                    item['.key'],
                    item
                  )
                "
                v-model="item.active"
              ></v-switch>
            </template>

            <template v-slot:[`item.image`]="{ item }">
              <v-avatar v-if="item.image" :color="item.backgroundColor">
                <img :src="item.image.original" />
              </v-avatar>
            </template>

            <template v-slot:[`item.date`]="{ item }">
              {{ item.date | filterDate }}
            </template>

            <template v-slot:[`item.options`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-1"
                    fab
                    v-on="on"
                    :loading="loading"
                    @click="editPrize(item)"
                    dark
                    x-small
                    :elevation="0"
                    color="rgba(0, 0, 0, 0.6)"
                  >
                    <v-icon dark>fa-pencil-alt</v-icon>
                  </v-btn>
                </template>
                <span>{{ $tc("table.edit") }}</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-1"
                    fab
                    :loading="loading"
                    v-on="on"
                    @click="showDelete(item)"
                    dark
                    x-small
                    :elevation="0"
                    color="red darken-4"
                  >
                    <v-icon dark>fa-trash</v-icon>
                  </v-btn>
                </template>
                <span>{{ $tc("table.delete") }}</span>
              </v-tooltip>
              <!-- <v-btn
                  :loading="loading"
                  @click="editPrize(item)"
                  small
                  color="primary"
                  >Editar</v-btn
                >
                <v-btn
                  class="ml-5 white--text"
                  :loading="loading"
                  @click="showDelete(item)"
                  small
                  color="red darken-4"
                  >Eliminar</v-btn
                > -->
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-model="addDialog" v-if="addDialog" max-width="800px">
      <Add
        @cancel="addDialog = false"
        :selectedItem="selectedPrize"
        @success="handleSuccess"
        :event="selectedItem"
      ></Add>
    </v-dialog>

    <v-dialog
      persistent
      v-model="deleteDialog"
      v-if="deleteDialog"
      max-width="500px"
    >
      <Delete
        @cancel="deleteDialog = false"
        :name="selectedPrize.title"
        :loading="loading"
        ref="deleteComponent"
        @success="confirmDeleted"
      ></Delete>
    </v-dialog>

    <v-dialog
      persistent
      v-model="sortDialog"
      v-if="sortDialog"
      max-width="500px"
    >
      <Sort
        @cancel="sortDialog = false"
        :items="prizes"
        :loading="loading"
        @success="handleSort"
      ></Sort>
    </v-dialog>
  </div>
</template>
  <script>
import { db } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import Add from "./add-prize";
import Delete from "@/components/delete";
import Sort from "@/components/sort-2";

import { mapState } from "vuex";

export default {
  name: "prizes",
  props: ["selectedItem", "prize"],
  components: {
    Add,
    Delete,
    Sort,
  },
  data() {
    return {
      selectedPrize: null,
      snackbar: false,
      snackbarText: "",
      loading: true,
      addDialog: false,
      sortDialog: false,
      search: null,
      prizes: [],
      deleteDialog: false,
      headers: [
        //   { value: "image" },
        //   { text: "Posición", value: "position" },
        { text: "Telefono", value: "phone" },
        { text: "Fecha", value: "date" },
        //   { text: "Reclamados", value: "claimed" },
        //   { text: "Tipo", value: "type" },
        //   { text: "Activo", value: "active" },
        //   { value: "options", align: "end" },
      ],
    };
  },

  filters: {
    filterType(e) {
      let types = {
        // coupon: "Cupón",
        promotional: "Artículos promocionales",
        again: "Nuevo intento",
        none: "Ningún premio",
      };

      return types[e] ? types[e] : "";
    },

    filterDate(timestamp) {
      if (!timestamp) return "";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("DD/MM/YYYY hh:mm A");
    },
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    async handleSort(data) {
      this.loading = true;
      for (let i = 0; i < data.length; i++) {
        const prize = data[i];
        await db
          .collection(`games/${this.selectedItem[".key"]}/prizes`)
          .doc(prize[".key"])
          .update({
            position: i + 1,
          });
      }
      this.loading = false;
      this.sortDialog = false;
    },

    confirmDeleted() {
      this.loading = true;

      db.collection(`games/${this.selectedItem[".key"]}/prizes`)
        .doc(this.selectedPrize[".key"])
        .delete()
        // .update({
        //   deleted: true,
        //   deletedAt: new Date(),
        //   deletedBy: this.user[".key"],
        // })
        .then(() => {
          this.snackbarText = "Premio eliminado exitosamente.";
          this.snackbar = true;
          this.loading = false;
          this.deleteDialog = false;
        })
        .catch((err) => {
          this.snackbarText = err.message;
          this.snackbar = true;
          this.loading = false;
        });
    },

    showDelete(item) {
      this.selectedPrize = item;
      this.deleteDialog = true;
    },

    addPrize() {
      this.selectedPrize = null;
      this.addDialog = true;
    },

    editPrize(item) {
      this.selectedPrize = item;
      this.addDialog = true;
    },

    handleSuccess() {
      this.snackbarText = `Premio ${
        this.selectedPrize ? "editado" : "agregado"
      } correctamente.`;
      this.snackbar = true;
      this.addDialog = false;
    },

    switchControlChanged(data, prizeId, prize) {
      if (data && prizeId) {
        this.loading = true;
        db.collection(`games/${this.selectedItem[".key"]}/prizes`)
          .doc(prizeId)
          .update(data)
          .then((ref) => {
            this.loading = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            prize.active = !prize.active;
          });
      }
    },
  },
  async mounted() {
    await this.$binding(
      "prize",
      db
        .collection("games")
        .doc(this.selectedItem[".key"])
        .collection("prizes")
        .doc(this.prize[".key"])
    );

    console.log(this.prize);

    this.loading = false;
  },
};
</script>
  