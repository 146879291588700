<template>
  <v-container style="width: 100%">
    <v-row
      v-for="(style, index) of sortedStylesArray"
      :key="index"
      style="display: flex; align-items: center; width: 100%"
    >
      <v-col cols="12" sm="5" md="5">
        <v-autocomplete
          full-width
          item-value="id"
          rounded
          filled
          @change="styleChanged($event, index)"
          v-model="style.property"
          :items="styles"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.text"></v-list-item-title>
                <v-list-item-subtitle
                  >Propiedad css:
                  <b>{{ data.item.cssProperty }}</b></v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-text-field
          rounded
          full-width
          filled
          append-icon="fa-cloud-upload-alt"
          @click:append="
            imagesDialog = true;
            selectedProperty = index;
          "
          v-model="style.value"
          v-if="style.control == 'image'"
        />

        <v-text-field
          rounded
          filled
          full-width
          v-model="style.value"
          v-if="!style.control"
        />

        <v-select
          rounded
          filled
          full-width
          v-if="style.control == 'size'"
          v-model="style.value"
          :items="sizes"
        />

        <v-select
          rounded
          full-width
          filled
          v-if="style.control == 'repeat'"
          v-model="style.value"
          :items="repeat"
        />

        <v-select
          rounded
          full-width
          filled
          v-if="style.control == 'weight'"
          v-model="style.value"
          :items="weight"
        />

        <v-select
          rounded
          full-width
          filled
          v-if="style.control == 'adjustment'"
          v-model="style.value"
          :items="adjustment"
        />

        <v-select
          rounded
          full-width
          filled
          v-if="style.control == 'transform'"
          v-model="style.value"
          :items="transform"
        />

        <v-select
          rounded
          filled
          full-width
          v-if="style.control == 'font'"
          v-model="style.value"
          :items="fontFamily"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  :style="`font-family: ${data.item.value}; font-size:25px`"
                  v-html="data.item.text"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>

          <template v-slot:selection="data">
            <template>
              <p
                :style="`font-family: ${data.item.value}`"
                class="mt-3"
                v-html="data.item.text"
              ></p>
            </template>
          </template>
        </v-select>

        <input
          v-if="style.control == 'color'"
          v-model="style.value"
          style="width: 100%"
          type="color"
          class="mb-6"
        />
      </v-col>

      <v-col cols="12" sm="1" md="1">
        <v-btn @click="remove(index)" icon class="mb-3" color="red" dark>
          <v-icon dark> fas fa-trash </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-btn @click="addStyle" class="white--text" rounded color="#727272">
          <v-icon left> fas fa-plus </v-icon>
          Agregar estilo</v-btn
        >
      </v-col>
    </v-row>

    <v-dialog v-if="imagesDialog" max-width="600px" v-model="imagesDialog">
      <select-file
        fileType="image"
        @updated="handleUpdate"
        @success="added"
        @cancel="imagesDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import selectFile from "../views/Zones/components/single-image/source.vue";

export default {
  name: "css-s",
  props: ["stylesArray"],
  components: {
    selectFile,
  },
  data() {
    return {
      sizes: [],
      imagesDialog: false,
      selectedProperty: null,
      fontFamily: [
        { text: "Helvetica (sans-serif)", value: "helvetica, sans-serif" },
        { text: "Arial (sans-serif)", value: "arial, sans-serif" },
        { text: "Arial Black (sans-serif)", value: "arial black, sans-serif" },
        { text: "Verdana (sans-serif)", value: "verdana, sans-serif" },
        { text: "Tahoma (sans-serif)", value: "'Tahoma', sans-serif" },
        {
          text: "Trebuchet MS (sans-serif)",
          value: "trebuchet ms, sans-serif",
        },
        { text: "Impact (sans-serif)", value: "impact, sans-serif" },
        { text: "Gill Sans (sans-serif)", value: "gill sans, sans-serif" },
        { text: "Times New Roman (serif)", value: "times new roman, serif" },
        { text: "Georgia (serif)", value: "georgia, serif" },
        { text: "Palatino (serif)", value: "palatino, serif" },
        { text: "Baskerville (serif)", value: "baskerville, serif" },
        { text: "Andalé Mono (monospace)", value: "andale mono, monospace" },
        { text: "Courier (monospace)", value: "courier, monospace" },
        { text: "Lucida (monospace)", value: "lucida, monospace" },
        { text: "Monaco (monospace)", value: "monaco, monospace" },
        { text: "Bradley Hand (cursive)", value: "bradley hand, cursive" },
        {
          text: "Brush Script MT (cursive)",
          value: "brush script m, cursivet",
        },
        { text: "Luminari (fantasy)", value: "luminari, fantasy" },
        { text: "Comic Sans MS (cursive)", value: "comic sans ms, cursive" },
        { text: "Graphik Regular", value: "Graphik-Regular" },
        { text: "Graphik Semibold", value: "Graphik-Semibold" },
      ],

      weight: [
        { text: "Normal (normal)", value: "normal" },
        { text: "Negrita (bold)", value: "bold" },
        { text: "Más ligera (lighter)", value: "lighter" },
        { text: "Más marcada (bolder)", value: "bolder" },
      ],

      // text-transform: capitalize;
      // Choose example 1
      // text-transform: uppercase;
      // Choose example 2
      // text-transform: lowercase;
      // Choose example 3
      // text-transform: none;
      // Choose example 4
      // text-transform: full-width;
      // Choose example 5
      // text-transform: full-size-kana;
      // Copy to Clipboard

      transform: [
        { text: "Solo la primera letra mayúscula", value: "capitalize" },
        { text: "Mayúscula", value: "uppercase" },
        { text: "Minúscula", value: "lowercase" },
        // { text: "Más marcada (bolder)", value: "bolder" },
      ],

      adjustment: [
        { text: "Cubrir (cover)", value: "cover" },
        { text: "Contenedor (contain)", value: "contain" },
      ],

      repeat: [
        { text: "No repetir (no-repeat)", value: "no-repeat" },
        { text: "Repetir (repeat)", value: "repeat" },
      ],
      styles: [
        {
          text: "Color de la letra",
          id: "color",
          cssProperty: "color",
          control: "color",
        },
        {
          text: "Color del fondo",
          id: "backgroundColor",
          cssProperty: "background-color",
          control: "color",
        },
        {
          text: "Fuente",
          id: "fontFamily",
          cssProperty: "font-family",
          control: "font",
        },
        {
          text: "Tamaño de la letra",
          id: "fontSize",
          cssProperty: "font-size",
          control: "size",
        },
        {
          text: "Grosor de la letra",
          id: "fontWeight",
          cssProperty: "font-weight",
          control: "weight",
        },
        {
          text: "Imagen de fondo",
          id: "backgroundImage",
          cssProperty: "background-image",
          control: "image",
        },

        {
          text: "Radio del border",
          id: "borderRadius",
          cssProperty: "border-radius",
          control: "size",
        },

        {
          text: "Repetir imágen de fondo",
          id: "backgroundRepeat",
          cssProperty: "background-repeat",
          control: "repeat",
        },

        {
          text: "Tamaño de imagen de fondo",
          id: "backgroundSize",
          cssProperty: "background-size",
          control: "adjustment",
        },

        {
          text: "Transformación del texto",
          id: "textTransform",
          cssProperty: "text-transform",
          control: "transform",
        },
        {
          text: "Margen",
          id: "margen",
          cssProperty: "margen",
          control: "size",
        },
        {
          text: "Margen",
          id: "margen",
          cssProperty: "margen",
          control: "size",
        },
        {
          text: "Margen superior",
          id: "margenTop",
          cssProperty: "margen-top",
          control: "size",
        },
        {
          text: "Margen inferior",
          id: "margenBottom",
          cssProperty: "margen-bottom",
          control: "size",
        },
        {
          text: "Margen derecho",
          id: "margenRight",
          cssProperty: "margen-right",
          control: "size",
        },
        {
          text: "Margen izquierdo",
          id: "margenLeft",
          cssProperty: "margen-left",
          control: "size",
        },
        {
          text: "Padding",
          id: "padding",
          cssProperty: "padding",
          control: "size",
        },
        {
          text: "Padding superior",
          id: "paddingTop",
          cssProperty: "padding-top",
          control: "size",
        },
        {
          text: "Padding inferior",
          id: "paddingBottom",
          cssProperty: "padding-bottom",
          control: "size",
        },
        {
          text: "Padding derecho",
          id: "paddingRight",
          cssProperty: "padding-right",
          control: "size",
        },
        {
          text: "Padding izquierdo",
          id: "paddingLeft",
          cssProperty: "padding-left",
          control: "size",
        },
      ],
    };
  },

  computed: {
    sortedStylesArray() {
      function compare(a, b) {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      }

      return this.stylesArray.sort(compare);
    },
  },

  methods: {
    handleUpdate(e) {
      console.log(e);
    },
    styleChanged(event, index) {
      let style = this.styles.find((e) => e.id == event);
      this.stylesArray[index].control = style.control;
      this.stylesArray[index].value = "";
    },
    addStyle() {
      this.stylesArray.push({
        property: "",
        value: "",
        control: "",
      });
    },
    added(e) {
      this.stylesArray[this.selectedProperty].value = e.url;
      this.imagesDialog = false;
    },

    remove(index) {
      this.stylesArray = this.stylesArray.splice(index, 1);
    },
  },
  mounted() {
    for (let i = 0; i < 95; i = i + 1) {
      this.sizes.push({ text: `${i + 1} Unidades`, value: `${i + 1}vw` });
    }
  },
};
</script>