<template>
  <v-container fluid class="px-2">
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-row class="header-title-actions">
      <v-col cols="12" sm="6" md="6">
        <h1 class="page-title">Ruleta</h1>
      </v-col>

      <v-col class="align-end" cols="12" sm="6" md="6">
        <v-btn
          color="buttons"
          class="white--text"
          :loading="loading"
          @click="newEvent"
        >
          <v-icon right dark class="mr-3">fas fa-plus</v-icon>
          Agregar
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <!-- <v-col cols="12" sm="12" md="12">
        <v-text-field
          clearable
          filled
          rounded
          v-model="search"
          hide-details
          label="Buscar"
          prepend-inner-icon="fa-search"
          class="d-md-flex mb-5 ml-5 mr-3 search"
        />
      </v-col> -->

      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="roulette"
          :items-per-page="10"
          :loading="loading"
          class="pl-5 pr-5"
          :search="$store.state.search"
          :sort-desc="true"
          item-key=".key"
          sort-by="createdAt"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.code`]="{ item }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.code)"
            >
              {{ item.code }}
            </v-chip>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.description`]="{ item }">
            <div style="max-widht: 500px" class="no-wrap">
              {{ item.description }}
            </div>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <!-- <v-btn
              :loading="loading"
              small
              @click="showPrizes(item)"
              class="white--text mr-3"
              color="primary"
            >
              Premios
            </v-btn> -->

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-1"
                  fab
                  v-on="on"
                  :loading="loading"
                  @click="showPrizes(item)"
                  dark
                  x-small
                  :elevation="0"
                  color="#ff5900"
                >
                  <v-icon dark>fa-gifts</v-icon>
                </v-btn>
              </template>
              <span> Premios</span>
            </v-tooltip>

            <!-- <v-btn
              small
              :loading="loading"
              @click="edit(item)"
              class="white--text mr-3"
              color="primary"
            >
              Editar
            </v-btn> -->

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-1"
                  fab
                  v-on="on"
                  :loading="loading"
                  @click="edit(item)"
                  dark
                  x-small
                  :elevation="0"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  <v-icon dark>fa-pencil-alt</v-icon>
                </v-btn>
              </template>
              <span>{{ $tc("table.edit") }}</span>
            </v-tooltip>

            <!-- <v-btn
              small
              :loading="loading"
              @click="showDelete(item)"
              class="white--text"
              color="red darken-4"
            >
              Eliminar
            </v-btn> -->

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-1"
                  fab
                  :loading="loading"
                  v-on="on"
                  @click="showDelete(item)"
                  dark
                  x-small
                  :elevation="0"
                  color="red darken-4"
                >
                  <v-icon dark>fa-trash</v-icon>
                </v-btn>
              </template>
              <span>{{ $tc("table.delete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="addDialog" v-if="addDialog" max-width="700px">
      <Add
        @cancel="addDialog = false"
        :selectedItem="selectedItem"
        @success="handleSuccess"
      ></Add>
    </v-dialog>

    <v-dialog
      persistent
      v-model="prizesDialog"
      v-if="prizesDialog"
      max-width="1200px"
    >
      <Prizes
        @cancel="prizesDialog = false"
        :selectedItem="selectedItem"
        @success="handleSuccess"
      ></Prizes>
    </v-dialog>

    <v-dialog
      persistent
      v-model="deleteDialog"
      v-if="deleteDialog"
      max-width="500px"
    >
      <Delete
        @cancel="deleteDialog = false"
        :name="selectedItem.title"
        ref="deleteComponent"
        :loading="loading"
        @success="confirmDeleted"
      ></Delete>
    </v-dialog>
  </v-container>
</template>

<script>
// import Lottie from "@/components/Lottie";
// import lottieSettings from "@/mixins/lottieMixin";
import { db } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import Add from "./add";
import Prizes from "./prizes";
import Delete from "@/components/delete";

import { mapState } from "vuex";

export default {
  name: "roulette",
  components: {
    // lottie: Lottie,
    Add,
    Prizes,
    Delete,
  },
  data() {
    return {
      loading: true,
      snackbar: false,
      snackbarText: "",
      roulette: [],
      search: "",
      addDialog: false,
      deleteDialog: false,
      prizesDialog: false,
      selectedItem: null,
      headers: [
        {
          text: "Nombre",
          value: "title",
          class: "nowrap",
        },

        // {
        //   text: "Código",
        //   value: "code",
        //   class: "nowrap",
        // },

        {
          text: "Descripción",
          value: "description",
          class: "nowrap",
          width: "500px",
        },

        {
          text: "Activo",
          value: "active",
          class: "nowrap",
        },
        {
          value: "options",
          class: "nowrap",
          align: "end",
          width: "400px",
        },
      ],
    };
  },

  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    confirmDeleted() {
      this.loading = true;

      console.log("auiiiii");

      db.collection("games")
        .doc(this.selectedItem[".key"])
        .delete()
        // .update({
        //   deleted: true,
        //   deletedAt: new Date(),
        //   deletedBy: this.user[".key"],
        // })
        .then(() => {
          this.snackbarText = "Ruleta eliminada exitosamente.";
          this.snackbar = true;
          this.loading = false;
          this.deleteDialog = false;
        })
        .catch((err) => {
          this.snackbarText = err.message;
          this.snackbar = true;
          this.loading = false;
        });
    },
    switchControlChanged(data, eventId, event) {
      if (data && eventId) {
        this.loading = true;
        db.collection("games")
          .doc(eventId)
          .update(data)
          .then(() => {
            this.loading = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch(() => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            event.active = !event.active;
          });
      }
    },

    showDelete(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    newEvent() {
      this.selectedItem = null;
      this.addDialog = true;
    },
    showPrizes(item) {
      this.selectedItem = item;
      this.prizesDialog = true;
    },
    edit(item) {
      this.selectedItem = item;
      this.addDialog = true;
    },
    handleSuccess() {
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
      this.addDialog = false;
    },
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "evento");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    await this.$binding(
      "roulette",
      db
        .collection("games")
        .where("groupId", "==", this.groupId)
        .where("type", "==", "roulette")
    );

    this.loading = false;
  },
};
</script>

<style scoped>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-wrap {
  max-width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
