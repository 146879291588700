<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">{{ $t("zones.singleFile.select_file") }}</span>
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container style="min-height: 50vh" fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <search :loading="loading" />
            </v-col>
          </v-row>
          <v-row v-if="loading">
            <v-col
              v-for="(item, index) of 12"
              :key="index"
              cols="12"
              sm="2"
              md="2"
            >
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-else class="pl-5 pr-5">
            <v-radio-group style="width: 100%" row v-model="selectedFile">
              <v-col v-if="filteredItems.length == 0" cols="12" sm="12" md="12">
                <p style="text-align: center">
                  {{ $t("zones.singleFile.no_results") }} 😅
                </p>
              </v-col>
              <v-col
                v-else
                cols="12"
                sm="2"
                md="2"
                v-for="(item, index) of filteredItems"
                :key="index"
              >
                <v-img
                  class="image"
                  :lazy-src="item.screenshot"
                  @click="selectedFile = item['.key']"
                  :src="fileType == 'video' ? item.screenshot : item.url"
                >
                  <input
                    type="radio"
                    name="file"
                    v-model="selectedFile"
                    style="
                      position: absolute;
                      left: 5px;
                      top: 5px;
                      cursor: pointer;
                    "
                    :value="item['.key']"
                    :id="item['.key']"
                  />
                </v-img>
                <div
                  :style="
                    selectedFile == item['.key']
                      ? 'background-color : rgb(195 195 195)'
                      : '#c3bdbd3b'
                  "
                  @click="showFile(item)"
                  class="description"
                >
                  <p class="font-weight-bold pa-0 ma-0">{{ item.name }}</p>
                  <p
                    class="pa-0 ma-0"
                    style="
                      font-size: 13px;
                      max-width: 100%;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    "
                  >
                    {{ item.description }}
                  </p>
                  <small
                    >{{ $t("zones.singleFile.uploaded_at") }}
                    {{ item.createdAt | filterDate }}</small
                  >
                </div>
              </v-col>
            </v-radio-group>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn
                  :loading="loading"
                  @click="save"
                  class="mr-5"
                  color="buttons"
                  >{{ $t("button.save") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog
      hide-overlay
      fullscreen
      max-width="60%"
      v-if="viewFileDialog"
      v-model="viewFileDialog"
    >
      <view-file
        :url="item.url"
        :type="item.type"
        :lazy="item.screenshot"
        @cancel="viewFileDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";
import viewFile from "@/components/view-file";

export default {
  name: "all-files",
  props: ["fileType", "selectedItem"],
  components: {
    viewFile,
  },
  data() {
    return {
      allFilesDialog: false,
      images: [],
      loading: true,
      selectedFile: null,
      viewFileDialog: false,
      item: "",
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("L");
    },
  },

  computed: {
    ...mapState(["search", "groupId"]),
    filteredItems() {
      if (this.search)
        return this.images.filter(
          (item) =>
            item.name.toLowerCase().includes(this.search.toLowerCase()) ||
            (item.description &&
              item.description
                .toLowerCase()
                .includes(this.search.toLowerCase()))
        );
      else {
        return this.images;
      }
    },
  },

  methods: {
    save() {
      if (this.selectedFile) {
        let result = this.images.find(
          (item) => item[".key"] == this.selectedFile
        );

        this.$emit("success", result);
      }
    },
    showFile(item) {
      this.item = item;
      this.viewFileDialog = true;
    },
  },

  mounted() {
    if (this.selectedItem) {
      this.selectedFile = this.selectedItem.data.fileId;
    }

    db.collection("files")
      .where("groupId", "==", this.groupId)
      .where("type", "==", this.fileType)
      .onSnapshot((response) => {
        this.images = [];
        response.forEach((item) => {
          let image = item.data();
          image[".key"] = item.id;
          this.images.push(image);
        });
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.image {
  width: 190px;
  min-width: 190px;
  max-width: 190px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.v-input--selection-controls__input .v-icon {
  background-color: white;
}

.description {
  background-color: #c3bdbd3b;
  cursor: pointer;
  width: 190px;
  padding: 10px;
  min-height: 80px;
  max-height: 80px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

input[type="radio"] {
  background-color: #ddd;
  background-image: -webkit-linear-gradient(
      0deg,
      transparent 20%,
      hsla(0, 0%, 100%, 0.7),
      transparent 80%
    ),
    -webkit-linear-gradient(90deg, transparent 20%, hsla(0, 0%, 100%, 0.7), transparent
          80%);
  border-radius: 10px;
  box-shadow: inset 0 1px 1px hsla(0, 0%, 100%, 0.8),
    0 0 0 1px hsla(0, 0%, 0%, 0.6), 0 2px 3px hsla(0, 0%, 0%, 0.6),
    0 4px 3px hsla(0, 0%, 0%, 0.4), 0 6px 6px hsla(0, 0%, 0%, 0.2),
    0 10px 6px hsla(0, 0%, 0%, 0.2);
  cursor: pointer;
  display: inline-block;
  height: 20px;
  position: relative;
  width: 20px;
  -webkit-appearance: none;
}
input[type="radio"]:after {
  background-color: rgb(212, 209, 209);
  border-radius: 25px;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.4),
    0 1px 1px hsla(0, 0%, 100%, 0.8);
  content: "";
  display: block;
  height: 14px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 14px;
}
input[type="radio"]:checked:after {
  background-color: #008bbf;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.4),
    inset 0 2px 2px hsla(0, 0%, 100%, 0.4), 0 1px 1px hsla(0, 0%, 100%, 0.8),
    0 0 2px 2px hsla(0, 70%, 70%, 0.4);
}
</style>