<template>
  <v-container fluid>
    <v-col cols="12" sm="12" md="12">
      <!-- <p>Nombre del evento</p> -->
      <v-text-field
        filled
        rounded
        label="Nombre de la ruleta"
        v-model="roulette.title"
      />
    </v-col>

    <v-col cols="12" sm="12" md="12">
      <!-- <p>Descripción del evento</p> -->
      <v-textarea
        filled
        rows="4"
        rounded
        label="Descripción de la ruleta"
        v-model="roulette.description"
      />
    </v-col>

    <v-col cols="12" sm="12" md="12">
      <p class="font-weight-bold mb-0 pb-0">Términos y condiciones</p>
      <v-divider class="mb-4" />
      <v-text-field
        filled
        append-icon="fas fa-trash"
        v-for="(k, id) of roulette.terms"
        class="mb-0"
        :key="id"
        :ref="`term-${id}`"
        v-on:keyup.enter="onEnter(id)"
        @click:append="remove(id)"
        rounded
        hint="Presione Enter insertar otro elemento"
        v-model="roulette.terms[id]"
      />
    </v-col>
  </v-container>
</template>


<script>
export default {
  name: "roulette-general",
  props: ["roulette"],

  methods: {
    onEnter(id) {
      this.roulette.terms.splice(id + 1, 0, "");
      setTimeout(() => {
        this.$refs[`term-${id + 1}`][0].focus();
      }, 100);
    },

    remove(id) {
      if (this.roulette.terms.length > 1) {
        this.roulette.terms.splice(id, 1);
      } else {
        this.roulette.terms = [""];
      }
    },
  },
};
</script>